import Vue from 'vue'
import "@babel/polyfill";
import FS from './app'
import axios from 'axios'
import VueLogger from 'vuejs-logger';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import { flatten } from 'nest-deep';
import moment from 'moment-timezone';
import * as XLSX from "xlsx";
import NProgress from 'nprogress'
import {loadProgressBar} from 'axios-progress-bar'

$ = window.$;

Vue.use(VueLogger, Vue.$log.debugOptions);
Vue.use(VueLodash, { lodash: lodash })

axios.defaults.headers.common['X-CSRF-TOKEN'] = FS.data.token;
axios.defaults.headers.common['Content-Type'] = "application/json";

NProgress.configure(
    {parent: '.content-wrapper', trickleRate: 0.02, trickleSpeed: 800});
window.NProgress = NProgress

const timezone = moment.tz.guess();
const wrapper = $("div.content-wrapper");
let clientId = wrapper.data("company_id");
let clientName = wrapper.data("company_name");

const vm = new Vue({
    el: '#app',
    data: {
        table: {
            name: `estateDeviceTable-${clientId}`,
            options: {
                tableRemove: false,
                tableColVis: true,
                tableCreate: false,
                tableEdit: true,
                tableSelect: true,
                tableExport: true,
                tableImport: false,
                tableColVisSelection: ":gt(1)"
            },
            order: [[2, "desc"]],
            headers: [
                {
                    title: "",
                    filterType: "none",
                    data: null,
                    orderable: false,
                    searchable: false,
                    render: function () {
                        return ""
                    },
                    className: "control no-edit",
                    responsivePriority: 0
                },
                {
                    data: "id",
                    title: "ID",
                    className: "no-edit",
                    orderable: true,
                    searchable: true,
                    render: (data, _type, row) => {
                        return '<a href="/estate/deviceDetails?id=' + row.id
                            + '">' + data + '</a>';
                    },
                    visible: false,
                    filterType: "text",
                    editField: "id"
                },
                {
                    data: "label",
                    title: "Label",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    render: (data, _type, row) => {
                        return '<a href="/estate/deviceDetails?id=' + row.id
                            + '">' + data + '</a>';
                    },
                    visible: true,
                    editField: "label"
                },
                {
                    data: "mac",
                    title: "MAC Address",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    render: (data, _type, row) => {
                        return '<a href="/estate/deviceDetails?id=' + row.id
                            + '">' + data + '</a>';
                    },
                    visible: true,
                    className: "no-edit",
                },
                {
                    data: "serial",
                    title: "Serial",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    editField: "serial",
                    render: (data, _type, _row) => {
                        if (!data) {
                            return "<i>Not set</i>";
                        }
                        return data;
                    },
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "deviceModel.type",
                    title: "Type",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    editField: "deviceModel.type",
                    render: (data, _type, _row) => {
                        if (!data) {
                            return "<i>Not set</i>";
                        }
                        return data;
                    },
                    defaultContent: "<i>Not set</i>",
                    className: "no-edit"
                },
                {
                    data: "assignee.name",
                    title: "Company",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    render: (data, _type, row) => {
                        if (row.assignee.companyType.connect) {
                            return '<a href="/customers/details?id='
                                + row.assignee.id
                                + '">' + data + '</a>';
                        } else {
                            return '<a href="/customers?id='
                                + row.assignee.id
                                + '">' + data + '</a>';
                        }
                    },
                    className: "no-edit"
                },
                {
                    data: "assignee.companyType.prettyName",
                    title: "Company Type",
                    orderable: true,
                    searchable: true,
                    render: (data, _type, row) => {
                        if (row.assignee.companyType.connect) {
                            return '<span class="label label-primary">{0}</span>'.format(
                                data)
                        } else {
                            return '<span class="label label-secondary">{0}</span>'.format(
                                data)
                        }
                    },
                    filterType: "text",
                    visible: true,
                    className: "no-edit"
                },
                {
                    data: "invoice",
                    title: "Invoice #",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    editField: "invoice",
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "batch",
                    title: "Order #",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    editField: "batch",
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "manufacturer.name",
                    title: "Manufacturer",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    editField: "manufacturer.id",
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "deviceModel.name",
                    title: "Device Model",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    editField: "deviceModel.id",
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "provision",
                    title: "Can Provision",
                    orderable: true,
                    searchable: true,
                    render: (data, _type, row) => {
                        if (data === true) {
                            return '<span class="label label-success" title="true">Yes</span>';
                        } else {
                            return '<span class="label label-info" title="false">Read Only</span>';
                        }
                    },
                    filterType: "noYes",
                    visible: true,
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "firmware.version",
                    title: "Selected Firmware",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    defaultContent: "<i>Not set</i>",
                    render: (data, _type, row) => {
                        if (data != null) {
                            if (row.firmwareMatch) {
                                return `<span class="label label-success" title="Matches">${data}</span>`;
                            } else {
                                return `<span class="label label-info" title="Does Not Match with Installed Version">${data}</span>`;
                            }
                        }
                    },
                },
                {
                    data: "state",
                    title: "State",
                    orderable: true,
                    searchable: true,
                    filterType: "noYes",
                    visible: true,
                    defaultContent: "<i>Not set</i>",
                    render: (data, _type, row) => {
                        return vm.state(data)
                    }
                },
                {
                    data: "firstLiveDate",
                    title: "First Live Date",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    render: (data, _type, row) => {
                        if (data === null) {
                            return "<i>Not set</i>"
                        }
                        return moment.utc(data, 'DD-MM-YYYY, hh:mm:ss').tz(
                            timezone).format("MMM Do YYYY, H:mm:ss")
                    },
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "lastProvisionDate",
                    title: "Last Connected",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    render: (data, _type, row) => {
                        if (data === null) {
                            return "<i>Not set</i>"
                        }
                        return moment.utc(data, 'DD-MM-YYYY, hh:mm:ss').tz(
                            timezone).format("MMM Do YYYY, H:mm:ss")
                    },
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "installedFirmware",
                    title: "Installed Firmware",
                    orderable: true,
                    searchable: true,
                    filterType: "noYes",
                    visible: true,
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "deviceModel.endOfLife",
                    title: "End of Life",
                    orderable: true,
                    searchable: true,
                    render: (data, _type, row) => {
                        return vm.createBooleanLabel(data, "", 6,
                            row.deviceModel)
                    },
                    filterType: "noYes",
                    visible: true,
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "deviceModel.endOfSale",
                    title: "End Of Sale",
                    orderable: true,
                    searchable: true,
                    filterType: "noYes",
                    render: (data, _type, row) => {
                        return vm.createBooleanLabel(data, "", 6,
                            row.deviceModel)
                    },
                    visible: true,
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "deviceModel.certificateSupported",
                    title: "Connect",
                    orderable: true,
                    searchable: true,
                    filterType: "supported",
                    render: (data, _type, row) => {
                        return vm.createBooleanLabel(data, "", 7,
                            row.deviceModel)
                    },
                    visible: true,
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "ipPrivate",
                    title: "Private IP",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    editField: "ipPrivate",
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "ipPublic",
                    title: "Public IP",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    editField: "ipPublic",
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "userAgent",
                    title: "Connected User-Agent",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    defaultContent: "<i>Not set</i>"
                },
                {
                    data: "deviceGroups",
                    title: "Device Groups",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    render: data => {
                        let groups = [];
                        data.forEach(element => {
                            let colourClass =
                                element.deviceGroupType === "SITE"
                                    ? "badge-primary"
                                    : "badge-secondary";

                            groups.push(`<div class="bp-1"><span
                                class="badge ${colourClass} tableBadge"
                                title="${element.name}">
                                ${element.name}
                              </span></div>`)
                        });
                        return groups.join(" ")
                    }
                },
                {
                    data: "regStatus",
                    title: "Registration",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    render: data => {
                        let groups = [];
                        data.forEach(element => {
                            let colourClass =
                                element.status
                                    ? "badge-success"
                                    : "badge-danger";

                            groups.push(`<div class="bp-1"><span
                                class="badge ${colourClass} tableBadge"
                                title="${element.line}">
                                ${element.line}
                              </span></div>`)
                        });
                        return groups.join(" ")
                    }
                },
                {
                    data: "ztdisabled",
                    title: "Manufacturer Redirection",
                    orderable: true,
                    searchable: true,
                    filterType: "noYes",
                    render: (data, _type, row) => {
                        if (row.provision && !row.assignee.ztdisabled) {
                            return vm.createBooleanLabel(data, "", 8,
                                row)
                        }
                        return "<i>Not applicable</i>";
                    },
                    visible: true
                },
                {
                    data: "uniteClient.name",
                    title: "Unite Client",
                    orderable: true,
                    searchable: true,
                    filterType: "text",
                    visible: true,
                    render: (data, _type, row) => {
                        if (!data) {
                            return "<i>Not applicable</i>";
                        }
                        let colourClass = "label-info";
                        if (row.uniteClient.status === "CLOSED") {
                            colourClass = "label-warning";
                        } else if (row.uniteClient.status === "OPEN") {
                            colourClass = "label-success";
                        }
                        return `<span class="label ${colourClass} tableBadge"
                        title="${data}">
                            ${data}
                            </span>`
                    },
                    defaultContent: ""
                },
            ],
            editorHeaders: [
                {
                    name: "id",
                    label: "ID",
                    type: "hidden"
                },
                {
                    name: "label",
                    label: "Label",
                    multiEditable: false
                },
                {
                    name: "mac",
                    label: "MAC Address",
                    multiEditable: false
                }
            ],
            dtHandle: null,
            dtEditor: null,
            exportData: [],
            params: {}
        }
    },
    methods: {
        tableButtons() {
            let vm = this;
            let buttons = [];
            if (vm.table.options.tableColVis != null
                && vm.table.options.tableColVis) {
                buttons.push({
                    extend: 'collection',
                    text: '<i class="fad fa-line-columns"></i>',
                    buttons: ['columnsVisibility'],
                    className: 'btn-primary col-vis-btn',
                    columns: ":gt(1)"
                })
            }

            buttons.push({
                className: 'btn-primary',
                text: '<i class="fad fa-trash-undo-alt"></i>',
                action: function () {
                    vm.table.dtHandle.state.clear();
                    vm.table.dtHandle.colReorder.reset();
                    return false
                }
            });
            buttons.push({
                className: "btn-primary",
                text: '<i class="fad fa-download"></i>',
                action: () => {
                    vm.exportRequest();
                    return false
                }
            });
            return buttons
        },
        exportRequest: async function () {
            let vm = this;
            let url = '/api/v2/device/view?companyId=' + clientId;
            let data = this.table.params;
            console.log(data);
            data.draw = 1;
            data.start = 0;
            let pages;
            NProgress.start();

            async function requestAllPages(data) {
                return await new Promise(async resolve => {
                    let responses = [];

                    async function fetch(start, responses) {
                        return await new Promise(async resolve => {
                            data.start = start;
                            await axios.post(url, data).then(async response => {
                                responses.push(response);
                                if (data.start === 0) {
                                    pages = Vue._.range(
                                        data.start + data.length,
                                        response.data.recordsFiltered,
                                        data.length
                                    );
                                    await axios.all(
                                        pages.map(m => fetch(m, responses)));
                                    await resolve(responses)
                                } else {
                                    resolve(responses)
                                }
                            })
                        })
                    }

                    return await fetch(data.start, responses).then(async () => {
                        await resolve(
                            [
                                ...responses.map(response => {
                                    let flattened = [];
                                    response.data.data.forEach(item =>
                                        flattened.push(flatten(item))
                                    );
                                    return flattened
                                })
                            ].flat()
                        )
                    })
                })
            }

            await new Promise(async (resolve, reject) => {
                await requestAllPages(data)
                .then(async response => {
                    vm.table.exportData = response;
                    resolve(response)
                })
                .catch(error => reject(error))
            });

            console.log(vm.table);

            let ws = XLSX.utils.json_to_sheet(vm.table.exportData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, vm.table.name);
            let filename = `${vm.table.name}.xlsx`.replace("'", "");
            XLSX.writeFile(wb, filename);
            NProgress.done();
        },
        contentTable() {
            let vm = this;
            vm.table.dtHandle = $('#' + vm.table.name).DataTable({
                columns: vm.table.headers,
                serverSide: true,
                order: vm.table.order,
                ajax: {
                    contentType: 'application/json',
                    url: '/api/v2/device/view?companyId=' + clientId,
                    type: 'POST',
                    data: function (d) {
                        return JSON.stringify(d);
                    },
                    headers: {
                        'X-CSRF-TOKEN': FS.data.token
                    }
                },
                rowId: function (a) {
                    return a.id
                },
                colReorder: true,
                paging: true,
                searching: true,
                info: true,
                responsive: {
                    details: {
                        type: 'column',
                        target: 'td.control'
                    }
                },
                deferRender: true,
                select: false,
                processing: true,
                stateSave: true,
                dom: "<'row tableHeader no-padding'<'col-sm-4 pad'B><'col-sm-3 pad'i><'col-sm-5 pad'f>><'row'<'col-sm-12 'tr>><'row'<'col-sm-5'l><'col-sm-7 no-padding'p>>",
                initComplete: function () {
                    vm.createHeaderFilters(this)
                },
                buttons: vm.tableButtons()
            }).on('column-reorder', function (e, settings, details) {
                let to = $(this).find('tfoot').find(
                    "[data-column-index=" + details.to + "]");
                let from = $(this).find('tfoot').find(
                    "[data-column-index=" + details.from + "]");
                to.attr("data-column-index", details.from);
                from.attr("data-column-index", details.to);
            }).on('draw.dt', function () {
                $('.tooltip').remove();
                $('[data-tooltip="tooltip"]').tooltip();
                vm.table.pageInfo = vm.table.dtHandle.page.info();
                vm.table.params = JSON.parse(vm.table.dtHandle.ajax.params());
                window.Pace.stop()
            }).on('stateSaveParams.dt', function (e, settings, data) {
                data.search.search = "";
                data.columns.map(function (column) {
                    column.search.search = "";
                });
                data.start = 0;
            }).on('processing.dt', function (e, settings, processing) {
                if (processing) {
                    window.Pace.restart()
                }
            })
        },
        createHeaderFilters(table) {
            let api = table.api();
            let columns = api.init().columns;
            let columnsOrder = api.state().ColReorder;
            let state = api.state();
            table.api().columns().every(function (index) {
                if (!columns[columnsOrder[index]]
                    || columns[columnsOrder[index]].searchable) {
                    let column = this;
                    let filterType = columns[index].filterType;
                    if (filterType === 'text' || filterType === null) {
                        $('<input type="text" class="form-control" data-tooltip="tooltip" data-container="body" title="Filter by Column" data-column-index="'
                            + columnsOrder[index] + '"/>').val(
                            state.columns[columnsOrder[index]].search.search)
                        .appendTo($(column.footer()).empty())
                        .on('keyup change', function () {
                            let val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );
                            let index = $(this).attr("data-column-index");
                            api.column(index)
                            .search(this.value)
                            .draw();
                        });
                    }
                }
            });
        },
        state(value) {
            if (value === "INVALID_MODEL") {
                return "<span class=\"label label-danger\">Unknown Model</span>"
            }
            if (value === "UNKNOWN_MANUF") {
                return "<span class=\"label label-danger\">Unknown Manufacturer</span>"
            }
            if (value === "NEW") {
                return "<span class=\"label label-info\">New</span>"
            }
            if (value === "CONFIGURED") {
                return "<span class=\"label label-info\">Configured</span>"
            }
            if (value === "POOL") {
                return "<span class=\"label label-info\">Pool</span>"
            }
            if (value === "LIVE") {
                return "<span class=\"label label-success\">Live</span>"
            }
            if (value === "INVALID_INITIAL_PROVISIONING") {
                return "<span class=\"label label-danger\">Manufacturer Redirect Failed</span>"
            }
            return value;
        },
        createBooleanLabel(value, customClass, mode, row) {
            let text;
            let title;
            let classValue = value ? "badge-success" : "badge-danger";

            if (row === null) {
                return ""
            }

            switch (mode) {
                case 1:
                    text = value ? "Enabled" : "Disabled";
                    title = value ? "Enabled" : "Disabled";
                    break;
                case 2:
                    text = value ? "Yes" : "No";
                    title = value ? "Yes" : "No";
                    break;
                case 3:
                    text = value ? "On" : "Off";
                    title = value ? "On" : "Off";
                    break;
                case 4:
                    text = value ? "Supported" : "Not Supported";
                    title = value ? "Supported" : "Not Supported";
                    break;
                case 5:
                    text = value ? "Stable" : "Not Stable";
                    title = value ? "Stable" : "Not Stable";
                    break;
                case 6:
                    text = value
                        ? '<span class="label label-danger" title="Matches">Yes</span>'
                        : '<span class="label label-info" title="Matches">No</span>';
                    title = value ? "Yes" : "No";
                    break;
                case 7:
                    text = value
                        ? '<i class="text-success fad fa-shield-alt fa-lg"></i>'
                        : '<i class="text-info fad fa-shield-alt fa-lg"></i>';
                    title = value ? "Yes" : "No";
                    break;
                case 8:
                    text = value
                        ? `<i class="${row.ztpAttempts ? 'text-danger'
                            : 'text-info'} fad fa-times fa-lg" data-toggle="tooltip" data-placement="top" title="${row.ztpAttempts}"></i>`
                        : '<i class="text-success fad fa-check fa-lg"></i>';
                    title = value ? "Yes" : "No";
                    break;
                default:
                    text = value;
                    break
            }
            let htmlClass = "badge " + classValue + " " + customClass;

            if (mode === 6 || mode === 8 || mode === 7) {
                return text
            } else {
                return `<span class="${htmlClass} tableBadge" title="${title}">${text}</span>`
            }
        }
    },
    mounted() {
        let vm = this;
        let table = '<table class="table display dt-responsive no-wrap table-bordered table-hover serverSideDataTable dataTable subTable" id="'
            + vm.table.name + '" width="100%"><thead><tr>';
        let headers = '';
        vm.table.headers.forEach(function (ele) {
            headers += '<th>' + ele.title + '</th>'
        });
        table += headers;
        table += '</tr></thead><tfoot><tr>';
        table += headers;
        table += '</tr></tfoot></table>';
        $('#estateDeviceTable').replaceWith(table);
        vm.contentTable()
    }
});

$.fn.dataTable.ext.errMode = 'throw';

(function ($) {
    "use strict";

    if (typeof NProgress !== "undefined" && $.isFunction(NProgress.configure)) {
        NProgress.configure({
            showSpinner: false,
            ease: "ease",
            speed: 750
        });
    }
}.apply(this, [jQuery]));
